import './website.css';
import { useNavigate } from 'react-router';
import { t } from 'i18next';
import { ButtonComp } from 'src/components';
import { useEdit } from 'src/hooks/useEdit';
import toast from 'react-hot-toast';
import { HTTP_STATUSES } from 'src/Config/constant';
import { customerQueriesService } from '../Services/customerQueries';

function DeleteUs() {
  const navigateTo = useNavigate();
  const navigationToHome = () => {
    navigateTo('/', { replace: true });
  };

  const initialValues = {
    name: '',
    email_id: '',
    mobile_number: '',
    company_name: '',
    message: ''
  };
  const edit = useEdit(initialValues);

  const handleClick = async () => {
    try {
      let data = { ...edit.edits, title: 'Delete Account' };
      const response: any = await customerQueriesService.delete({
        data: data,
        successMessage: 'Account Deleted Successfully',
        failureMessage: 'Account Deleted UnSuccessfully'
      });
      console.log(response, 'response');
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        edit.reset();
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  return (
    <>
      <section>
        <div className="contact-page1">
          <div className="side-head">
            <a onClick={navigationToHome}>{t('WEBCONTACT.home')}</a> |{' '}
            <a className="current-view">{t('WEBCONTACT.deleteAccount')}</a>
          </div>
          <div className="contact-container">
            <form action="#">
              <div className="input-box">
                <span className="details">{t('WEBCONTACT.fullName')}</span>
                <input
                  type="text"
                  placeholder=""
                  value={edit.getValue('name')}
                  onChange={(e) => edit.update({ name: e.target.value })}
                />
              </div>
              <div className="input-box">
                <span className="details">{t('WEBCONTACT.emailAddress')}</span>
                <input
                  type="email"
                  placeholder=""
                  value={edit.getValue('email_id')}
                  onChange={(e) => edit.update({ email_id: e.target.value })}
                />
              </div>
              <div className="input-box">
                <span className="details">{t('WEBCONTACT.phoneNumber')}</span>
                <input
                  type="text"
                  placeholder=""
                  value={edit.getValue('mobile_number')}
                  onChange={(e) =>
                    edit.update({ mobile_number: e.target.value })
                  }
                />
              </div>
              <div className="input-box">
                <span className="details">{t('WEBCONTACT.companyName')}</span>
                <input
                  type="text"
                  placeholder=""
                  value={edit.getValue('company_name')}
                  onChange={(e) =>
                    edit.update({ company_name: e.target.value })
                  }
                />
              </div>
              <div className="input-box msg">
                <span className="details">{t('WEBCONTACT.message')}</span>
                <input
                  type="text"
                  placeholder=""
                  value={edit.getValue('message')}
                  onChange={(e) => edit.update({ message: e.target.value })}
                />
              </div>
              <div className="button">
                <ButtonComp
                  buttonText={t('WEBCONTACT.delete')}
                  btnWidth={167}
                  height={55}
                  style={{
                    marginBottom: '5rem'
                  }}
                  btnBorderRadius={50}
                  backgroundColor="#EC3900"
                  onClickButton={handleClick}
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
export default DeleteUs;
