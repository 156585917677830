import { CssBaseline } from '@material-ui/core';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';
import ThemeProvider from './theme/ThemeProvider';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';

enum AUTH_STATE {
  NOT_LOGGED_IN,
  CHECKING,
  SIGNED_IN
}

function App() {
  const content = useRoutes(router);

  useEffect(() => {
    // Suppress 404 errors in the console
    const originalConsoleError = console.error;
    console.error = (...args) => {
      if (args[0].includes('404')) {
        return; // Suppress 404 errors
      }
      originalConsoleError(...args);
    };

    return () => {
      // Cleanup: Reset to original console.error on unmount
      console.error = originalConsoleError;
    };
  }, []);

  return (
    <ThemeProvider>
      <CssBaseline />
      <Toaster
        position="top-center"
        toastOptions={{
          style: {
            padding: '10px',
            color: '#FFFF',
            fontSize: 15
          },
          success: {
            style: {
              background: 'green'
            },
            duration: 2000
          },
          error: {
            style: {
              background: 'red'
            },
            duration: 2000
          }
        }}
      />
      {content}
    </ThemeProvider>
  );
}

export default App;
