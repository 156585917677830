import {
  contactPic,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  location,
  phoneicon,
  mailicon
} from 'src/Assets';
import './website.css';
import { useNavigate } from 'react-router';
import { t } from 'i18next';
import { ButtonComp } from 'src/components';
import { Grid } from '@material-ui/core';
import { useEdit } from 'src/hooks/useEdit';
import { customerQueriesService } from '../Services/customerQueries';
import { HTTP_STATUSES } from 'src/Config/constant';
import toast from 'react-hot-toast';
function ContactUs() {
  const navigateTo = useNavigate();
  const navigationToHome = () => {
    navigateTo('/', { replace: true });
  };
  const locationDetails = [
    {
      id: 1,
      Address: t('Contact.office_location'),
      image: location
    },
    {
      id: 2,
      Address: t('Contact.location'),
      image: location
    }
  ];
  const initialValues = {
    name: '',
    email_id: '',
    mobile_number: '',
    company_name: '',
    message: ''
  };
  const edit = useEdit(initialValues);

  const handleClick = async () => {
    try {
      const response: any = await customerQueriesService.create({
        data: edit.edits,
        successMessage: 'Create Query Successfully',
        failureMessage: 'Create Query UnSuccessfully'
      });
      console.log(response, 'response');
      if (response?.status < HTTP_STATUSES.BAD_REQUEST) {
        edit.reset();
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };
  return (
    <>
      <img className="contact-pic" src={contactPic} alt="" />
      <section>
        <div className="contact-page1">
          <div className="side-head">
            <a onClick={navigationToHome}>{t('WEBCONTACT.home')}</a> |{' '}
            <a className="current-view">{t('WEBCONTACT.contactUs')}</a>
          </div>
          <div>
            <h1 className="contact-title">{t('WEBCONTACT.contactUs')}</h1>
            <p className="contact-subtitle">{t('WEBCONTACT.letsStart')}</p>
          </div>
          <div className="contact-container">
            <form action="#">
              <div className="input-box">
                <span className="details">{t('WEBCONTACT.fullName')}</span>
                <input
                  type="text"
                  placeholder=""
                  value={edit.getValue('name')}
                  onChange={(e) => edit.update({ name: e.target.value })}
                />
              </div>
              <div className="input-box">
                <span className="details">{t('WEBCONTACT.emailAddress')}</span>
                <input
                  type="email"
                  placeholder=""
                  value={edit.getValue('email_id')}
                  onChange={(e) => edit.update({ email_id: e.target.value })}
                />
              </div>

              <div className="input-box">
                <span className="details">{t('WEBCONTACT.phoneNumber')}</span>
                <input
                  type="text"
                  placeholder=""
                  value={edit.getValue('mobile_number')}
                  onChange={(e) => {
                    if (isNaN(Number(e.target.value))) {
                      return;
                    }
                    edit.update({ mobile_number: e.target.value });
                  }}
                />
              </div>
              <div className="input-box">
                <span className="details">{t('WEBCONTACT.companyName')}</span>
                <input
                  type="text"
                  placeholder=""
                  value={edit.getValue('company_name')}
                  onChange={(e) =>
                    edit.update({ company_name: e.target.value })
                  }
                />
              </div>

              <div className="input-box msg">
                <span className="details">{t('WEBCONTACT.message')}</span>
                <input
                  type="text"
                  placeholder=""
                  value={edit.getValue('message')}
                  onChange={(e) => edit.update({ message: e.target.value })}
                />
              </div>

              <div className="button">
                <ButtonComp
                  buttonText={t('WEBCONTACT.send')}
                  btnWidth={167}
                  height={55}
                  style={{
                    marginBottom: '5rem'
                  }}
                  btnBorderRadius={50}
                  backgroundColor="#6cb044"
                  onClickButton={handleClick}
                />
              </div>
            </form>
          </div>
        </div>
        <Grid container direction="row">
          <Grid item xs={12} md={12} lg={6}>
            <iframe
              width="100%"
              height="500px"
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3888.854921103917!2d80.22757051482144!3d12.917044590891715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDU1JzAxLjQiTiA4MMKwMTMnNDcuMSJF!5e0!3m2!1sen!2sin!4v1689760000966!5m2!1sen!2sin"
              style={{ border: 0 }}
              loading="lazy"
              className="map-container"
            />
            {/*             <div className="map-address-container">
              <p
                style={{
                  fontFamily: 'Space Grotesk',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '74px',
                  marginTop: '-10px'
                }}
              >
                {t('WEBCONTACT.officeAddress')}
              </p> */}
            {/*               <div className="location-map">
                <img
                  src={locationDetails[0]?.image}
                  style={{ width: '50px', height: '50px' }}
                  alt=""
                />
                <span className="location-text">
                  {locationDetails[0]?.Address}
                </span>
              </div>
              <div className="phone-map" style={{ marginTop: '0.8rem' }}>
                <img
                  src={phoneicon}
                  style={{ width: '50px', height: '50px' }}
                  alt=""
                />
                <span className="phone-text">
                  (+91) 89399 09555, (+91) 89399 76555
                </span>
              </div>
              <div className="location-map">
                <img
                  src={mailicon}
                  style={{ width: '50px', height: '50px' }}
                  alt=""
                />
                <span className="phone-text">support@dustmanrecycling.com</span>
              </div> 
            </div>*/}
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <iframe
              width="100%"
              height="500px"
              src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3888.632522655224!2d79.90498431482155!3d12.931323990882328!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDU1JzUyLjgiTiA3OcKwNTQnMjUuOCJF!5e0!3m2!1sen!2sin!4v1689656203758!5m2!1sen!2sin"
              style={{ border: 0 }}
              loading="lazy"
              className="map-container"
            />
            <div className="map-address-container">
              <p
                style={{
                  fontFamily: 'Space Grotesk',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: '20px',
                  lineHeight: '74px',
                  marginTop: '-10px'
                }}
              >
                {t('WEBCONTACT.ourLocations')}
              </p>
              <div className="location-map">
                <img
                  src={locationDetails[1]?.image}
                  style={{ width: '50px', height: '50px' }}
                  alt=""
                />
                <span className="location-text">
                  {locationDetails[0]?.Address}
                </span>
              </div>
              <div className="location-map">
                <img
                  src={locationDetails[1]?.image}
                  style={{ width: '50px', height: '50px' }}
                  alt=""
                />
                <span className="location-text">
                  {locationDetails[1]?.Address}
                </span>
              </div>
              <div className="phone-map" style={{ marginTop: '0.8rem' }}>
                <img
                  src={phoneicon}
                  style={{ width: '50px', height: '50px' }}
                  alt=""
                />
                <span className="phone-text">
                  (+91) 89399 09555, (+91) 89399 76555
                </span>
              </div>
              <div className="location-map">
                <img
                  src={mailicon}
                  style={{ width: '50px', height: '50px' }}
                  alt=""
                />
                <span className="phone-text">support@dustmanrecycling.com</span>
              </div>
            </div>
          </Grid>
        </Grid>

        <div className="landing5">
          <div className="client-title">
            <p>{t('WEBCONTACT.ourClients')}</p>
            <h3>
              {t('WEBCONTACT.ourClients')}
              <br></br>
              {t('WEBCONTACT.workFor')}
            </h3>
          </div>
          <div className="client-list">
            <div className="client">
              <img src={client1} alt="" />
            </div>
            <div className="client">
              <img src={client2} alt="" />
            </div>
            <div className="client">
              <img src={client3} alt="" />
            </div>
            <div className="client">
              <img src={client4} alt="" />
            </div>
            <div className="client">
              <img src={client5} alt="" />
            </div>
            <div className="client">
              <img src={client6} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ContactUs;
