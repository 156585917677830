import { Config } from 'src/Config';
import { apiOptions } from 'src/Utils/apiOptions';
import { apiRequest } from 'src/Utils/apiRequest';

export const customerQueriesService = {
  create: async ({ data, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/createQuery`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  },
  delete: async ({ data, successMessage, failureMessage }) => {
    const options = await apiOptions({
      url: `${Config.BASE_URL}/api/deleteAccount`,
      method: 'post',
      data: data
    });
    const toastMessageConfig = {
      success: {
        message: successMessage
      },
      failure: {
        message: failureMessage
      }
    };
    return apiRequest(options, toastMessageConfig);
  }
};
